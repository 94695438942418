.mjpls-reviews {
  overflow-x: hidden;
  &__title {
    transform: translateX(600px);
  }

  .splide__list {
    margin-top: 60px;
  }
  .splide__slide {
    display: flex;
    padding: 0 75px;
    align-items: center;

    @media (max-width: 875px) {
      flex-direction: column;
    }
    .column {
      width: inherit;
      position: relative;

      @media (max-width: 875px) {
        width: fit-content;
        margin: 0 auto;
      }
      svg {
        position: relative;
        top: 15px;
        left: 25px;
        width: 459px;
        height: 415px;
        @media (max-width: 960px) {
          width: 419px;
          height: 329px;
        }

        @media (max-width: 560px) {
          width: 374px;
          height: 284px;
          top: -15px;
          left: 15px;
        }
        @media (max-width: 400px) {
          width: 250px;
          height: 150px;
          top: -15px;
          left: -25px;
        }
      }
      img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 345px;
        height: 340px;
        object-fit: cover;

        @media (max-width: 960px) {
          width: 305px;
          height: 300px;
        }

        @media (max-width: 560px) {
          width: 260px;
          height: 265px;
        }
      }
    }

    .review-content {
      padding-left: 25px;
      @media (max-width: 875px) {
        padding-left: 0px;
      }
      &__author {
        margin-top: 32px;
        p {
          @media (max-width: 875px) {
            margin-top: 0px !important;
          }
        }
      }

      h3 {
        margin-bottom: 0px;
        @media (max-width: 875px) {
          text-align: center;
        }
      }
      p {
        margin-top: 0px;
        @media (max-width: 875px) {
          margin-top: 32px;
          text-align: center;
        }
      }
    }
  }
  .splide__arrow {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: #828282 solid 1px;
    background-color: transparent;
    cursor: pointer;

    span {
      cursor: pointer;
      background-color: $blue;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      top: 12px;
      left: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;

      svg {
        cursor: pointer;
      }

      &:hover {
        top: 4px;
        left: 4px;
      }
    }
  }

  .splide__arrow--prev{
    span{
      svg{
        rotate: 180deg;
      }
    }
  }
}
