.textarea {
  label {
    display: block;
    color: $white;
  }
  textarea {
    width: 100%;
    display: block;
    border: none;
    font-size: 16px;
    border: $white-1 solid 1px;
    padding: 15px;
    background-color: transparent;
    color: $white-1;
    outline: none;
    transition: all 0.3s ease-in-out;
    &::placeholder{
        font-family: "Roboto";
    }
    &:focus {
      color: $white;
      border-color: $blue;
    }
  }
}
