.mjpls-text-split {
  .mjpls-header {
    align-items: flex-start;
    margin-bottom: 32px;
  }

  &__row {
    display: flex;
    gap: 32px;
    flex-direction: row;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    padding-right: 25px;
    @media (min-width: 600px) {
      border-right: $blue solid 2px;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }
}
