.text-image {
  overflow-x: hidden;
  position: relative;
  &__title {
    transform: translateX(300px);
  }

  .text-image-content {
    .row {
      display: flex;
      margin-top: 60px;
      align-items: flex-start;
      padding-bottom: 75px;

      @media (max-width: 930px) {
        flex-direction: column;
        align-items: center;
      }
      .column {
        position: relative;
        @media (min-width: 800px) {
          &:first-child {
            margin-right: 50px;
          }
        }
        img {
          width: 500px;
          height: 500px;
          object-fit: cover;
          object-position: center center;
          @media (max-width: 615px) {
            width: 100%;
            height: auto;
          }
        }

        a {
          margin-top: 32px;
        }

        // Button
        .arrow-container {
          position: absolute;
          bottom: 25px !important;
          right: 25px !important;
          width: fit-content !important;

          .arrow {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: #828282 solid 1px;
            background-color: transparent;
            cursor: pointer;
            position: relative;

            span {
              cursor: pointer;
              background-color: $blue;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              position: absolute;
              top: 12px;
              left: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.3s ease-in-out;

              svg {
                cursor: pointer;
              }

              &:hover {
                top: 4px;
                left: 4px;
              }
            }
          }
        }

        .number-title {
          position: relative;
          min-height: 182px;
          display: flex;
          align-items: center;
          .number {
            position: absolute;
            font-family: 'Akshar';
            font-style: normal;
            font-weight: 400;
            font-size: 182px;
            line-height: 182px;
            letter-spacing: 3.64px;
            color: #383838;
            z-index: -1;
          }

          h2 {
            font-family: 'Akshar';
            font-size: 36px;
            position: relative;
            left: 32px;
          }

          p {
            color: #d8d8d8;
          }
        }
        .text-image-list {
          display: flex;
          margin-top: 32px;
          ul {
            list-style: none;
            li {
              font-family: 'Roboto';
              margin-bottom: 16px;
              position: relative;
              color: $white;
            }
          }
        }

        .socials {
          display: flex;
          align-items: center;
          margin-top: 32px;

          a {
            margin-right: 25px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .text-image-left-circle {
    border: #828282 solid 1px;
    border-radius: 50%;
    width: 400px;
    height: 400px;
    position: absolute;
    bottom: 0px;
    left: -100px;
    opacity: 0.5;
    animation-fill-mode: both;
  }

  .text-image-top-circle {
    border: #828282 solid 1px;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    position: absolute;
    top: 0;
    right: -100px;
    opacity: 0.5;
    animation-fill-mode: both;
  }
}
