.mjpls-404-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  h1 {
    font-size: 15rem;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 3rem;
  }

  p {
    margin-bottom: 15px;
  }
}
