.mjpls-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  i {
    color: $blue;
  }
}
