.mjpls-button {
  background-color: $dark-grey;
  position: relative;
  padding: 15px 32px;
  border: none;
  font-family: "Akshar";
  width: fit-content;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &::after,
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 90%;
    height: 90%;
    border: 2px solid;
    transition: all 0.6s ease;
    border-radius: 2px;
  }

  &::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: $dark-grey;
    border-right-color: $dark-grey;
  }

  &::before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: $dark-grey;
    border-left-color: $dark-grey;
  }

  &__line {
    position: absolute;
    height: 2px;
    background-color: $blue;
    width: calc(100% - 64px);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    margin-bottom: 0px !important;
    background-color: transparent;
    &:before {
      width: 20%;
      height: 20%;
      border-top-color: $blue;
      border-left-color: $blue;
    }
    &:after {
      width: 20%;
      height: 20%;
      border-bottom-color: $blue;
      border-right-color: $blue;
    }
  }
}

.mjpls-button-group {
  display: flex;
  align-items: center;

  .mjpls-button:not(:last-child) {
    margin-right: 20px;
  }
}
