.mjpls-hero {
  display: flex;
  margin-top: 35px;
  position: relative;

  .mjpls-heading {
    @media (max-width: $breakpoint-xs) {
      font-size: 3.5rem;
    }

    @media (min-width: $breakpoint-sm) {
      font-size: 4.5rem;
    }

    @media (min-width: $breakpoint-md) {
      font-size: 6.25rem;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: 7.6rem;
    }
  }

  .single-line {
    max-width: 800px !important;
  }
  .mjpls_text_only {
    max-width: 550px;
    text-align: center;
    margin: 0 auto;
  }

  .mjpls_text_only_content {
    max-width: 100%;
    position: relative;
  }

  .mjpls-sub-text {
    max-width: 800px;
    margin: 0 auto;

    p {
      text-align: center;
    }
  }

  .align-left {
    margin-left: 0;
    margin-bottom: 25px;
    max-width: 600px;
    p {
      text-align: left;
    }
  }

  &__content {
    margin-top: 25px;
    max-width: 942px;
    width: 100%;
    position: absolute;
    

    @media (max-width: 610px) {
      position: relative;
      margin-top: 0px;

      .mjpls-prefix-first-word::after {
        bottom: 10px !important;
      }
    }

    &__title {
      margin-bottom: 25px;
      opacity: 1;
    }
  }

  &__images {
    display: flex;
    margin-left: auto;
    align-items: flex-end;

    @media (max-width: 610px) {
      display: none;
    }

    &__image-container {
      img:first-child {
        margin-right: 20px;
      }
    }
    &__image {
      width: 275px;
      height: 500px;
      object-fit: cover;
    }
  }

  &__social-medias {
    display: flex;
    margin-top: 50px;
    margin-bottom: 15px;
    &__icon {
      margin-right: 20px;
      font-family: 'Akshar';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 3.84px;
      text-transform: uppercase;
      color: $white-1;
    }
  }

  &__circle {
    bottom: -15px;
    right: -15px;
  }
}
