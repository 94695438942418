.glide__arrows {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    .glide__arrow {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: #828282 solid 1px;
      background-color: transparent;
      cursor: pointer;
      position: relative;

      span {
        cursor: pointer;
        background-color: $blue;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        top: 12px;
        left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;

        svg {
          cursor: pointer;
        }

        &:hover {
          top: 4px;
          left: 4px;
        }
      }
    }
  }