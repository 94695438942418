.contact-form {
  margin-top: 75px;
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 800px) {
      flex-direction: column-reverse;
    }

    .column {
      margin-top: 32px;
      width: 100%;
      padding: 0 15px;
      text-align: left;
    }
  }
  &__form {
    margin: 0 auto;

    width: 100%;
    max-width: 800px;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 700px;
      width: 100%;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      .column {
        margin-top: 32px;
        width: 100%;
        padding: 0 15px;
        text-align: left;

        small {
          display: block;
          margin-top: 10px;
        }
      }
    }
  }
  .mjpls-button {
    margin-left: 15px;
    margin-top: 32px;
  }

  &__info {
    &__socials {
      margin-top: 32px;
      display: flex;
      align-items: center;
    }
  }
}
