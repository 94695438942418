.mjpls-tiles {
  overflow: hidden;
  @media (min-width: 600px) {
    margin-bottom: 200px;
  }
  margin-bottom: 100px;

  &__title {
    transform: translateX(300px);

    &--title {
      top: 10px !important;
    }
  }

  &__tiles {
    margin-top: 64px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;

    &__tile {
      @media (min-width: 845px) {
        margin-top: 48px;
      }
      &--center {
        margin-top: 0;
      }

      &--image {
        img {
          width: 100%;
          height: 430px;
          object-fit: cover;
          object-position: center center;

          @media (max-width: 1160px) {
            height: 320px;
          }
        }
      }

      &--image-overlay {
        background-color: #00000065;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      &--title {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 15px;
      }
    }
  }
}
