.policies{
    max-width: 800px;
    margin: 0 auto;
    margin-top: 75px;

    h2{
        font-size: 2.5rem;
        font-weight: 700;
        margin-top: 25px;
    }

    a{
        color: $blue;
    }
}