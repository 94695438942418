.banner {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $grey;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    object-fit: cover;
    object-position: center center;
    z-index: 0;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  h2 {
    max-width: 850px;
    text-align: center;
    z-index: 2;

    @media screen and (max-width: 768px) {
      font-size: 3rem;
    }
  }
}
