.mjpls-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  h2 {
    text-align: center;
  }

  &__first-word {
    position: relative;
    display: inline-block;
    vertical-align: top;
    z-index: 1;
    white-space: nowrap;
    box-sizing: border-box;
    margin-right: 15px;
    &::after {
      height: 10px;
      bottom: 25px;
      content: '';
      position: absolute;
      width: 100%;
      height: 10px;
      background: $blue;
      left: 0;
      bottom: 24px;
      z-index: -1;
    }
  }

  &__heading {
    font-size: 4rem;
    @media (max-width: $breakpoint-xs) {
      font-size: 3.5rem;
    }
  }

  &__subtitle {
    text-align: center;
    color: $white;
    position: relative;
    font-weight: 400;
    padding-left: 40px;
    display: inline-block;
    letter-spacing: 0.02em;
    font-family: 'Roboto';

    &::before {
      content: '';
      width: 30px;
      height: 1px;
      background: $blue;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}
