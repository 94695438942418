@font-face {
  font-family: 'Akshar';
  src: url('../../assets/fonts/Akshar-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway-Black.ttf');
  font-weight: 900;
}

h2, h3, h4, h5 {
  font-family: 'Akshar';
  font-stretch: condensed;
  word-break: break-word;
  text-transform: capitalize;
  margin: 0;
  color: $white;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  font-family: 'Raleway';
  font-size: 3rem;
  -webkit-text-stroke: 1px $white-1;
  color: transparent;

  @media (min-width: $breakpoint-sm) {
    font-size: 4.5rem;
  }

  @media (min-width: $breakpoint-md) {
    font-size: 6.25rem;
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 7.6rem;
  }
}

h2 {
  font-size: 4rem;
  letter-spacing: 0.02em;
}

h3 {
  font-size: 1.5rem;
  letter-spacing: 0.08em;
  margin-bottom: 16px;

  @media (max-width: $breakpoint-xs) {
    font-size: 1.25rem;
  }
}

h4 {
  font-size: 2.25rem;
  letter-spacing: 0.08em;
  margin-bottom: 16px;
}

h5 {
  font-size: 1.5rem;
  letter-spacing: 0.08em;
}

p,
li,
label {
  font-weight: 400;
  color: $white-1;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.75;
  text-align: left;
  box-sizing: border-box;
  margin-top: 16px;
}

small {
  font-weight: 400;
  color: $white-1;
  font-family: 'Akshar';
  font-stretch: condensed;
  word-break: break-word;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 1.75;
  text-align: left;
  box-sizing: border-box;
  margin-top: 16px;
}

button,
a {
  font-family: 'Roboto';
  color: $white;
  text-transform: uppercase;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 3.83;
  position: relative;
  display: block;
  transition: all 0.3s ease-in-out;

  &:hover {
    margin-bottom: 10px;
  }
}

.background-title {
  font-size: 130px;
  -webkit-text-stroke: 1px $grey;
  color: transparent;
  font-family: 'Raleway';
  font-weight: 900;
  line-height: 0.8;
  position: relative;
  top: 40px;

  @media screen and (max-width: 800px) {
    font-size: 100px;
  }

  @media screen and (max-width: 600px) {
    font-size: 80px;
  }

  @media screen and (max-width: 450px) {
    font-size: 60px;
  }
}

.mjpls-prefix-heading-title {
  position: relative;
  .mjpls-prefix-first-word {
    position: relative;
    display: inline-block;
    vertical-align: top;
    z-index: 1;
    white-space: nowrap;
    box-sizing: border-box;
    margin-right: 15px;
    &::after {
      height: 10px;
      bottom: 25px;
      content: '';
      position: absolute;
      width: 100%;
      height: 10px;
      background: $blue;
      left: 0;
      bottom: 24px;
      z-index: -1;
    }
  }

  .mjpls-heading {
    font-size: 4rem;
  }
}

.mjpls-prefix-heading-subtitle {
  text-align: left;
  color: $white;
  position: relative;
  font-weight: 400;
  padding-left: 40px;
  display: inline-block;
  letter-spacing: 0.02em;
  font-family: 'Roboto';

  &::before {
    content: '';
    width: 30px;
    height: 1px;
    background: $blue;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.error {
  color: $red;
}

.success {
  color: $green;
}
