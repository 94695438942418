* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: $black;

  // Scrollbar
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $blue;
  }
  &::-webkit-scrollbar-track {
    background-color: $black;
  }

  main {
    overflow-x: hidden;
  }
}

.d-none {
  display: none;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
