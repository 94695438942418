.input {
width: 100%;
  label {
    display: block;
    margin-top: 0px;
    color: $white;
  }
  input {
    width: 100%;
    display: block;
    border: none;
    font-size: 16px;
    border-bottom: $white-1 solid 1px;
    padding: 10px 5px;
    background-color: transparent;
    color: $white-1;
    outline: none;
    transition: all 0.3s ease-in-out;
    color-scheme: dark;

    &:focus {
      color: $white;
      border-color: $blue;
    }
  }
}
