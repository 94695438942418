.content-section {
  position: relative;
  overflow-x: hidden;
  .content-section__title {
    transform: translateX(300px);
  }

  .content-section-content {
    background-color: $black-1;
    margin-left: auto;
    width: calc(100% - 35px);
    padding: 65px 0;

    @media (max-width: 930px) {
      width: 100%;
    }

    .content-section-content-top {
      display: flex;
      .column {
        width: 50%;
      }

      .content-section-profile {
        margin-top: 32px;
        display: flex;
        align-items: center;

        img {
          border-radius: 50%;
        }

        &__content {
          margin-left: 15px;
          .name {
            margin: 0px 0px 0px 0px;
          }

          .title {
            font-weight: 400;
            color: $white;
            font-family: 'Roboto', sans-serif;
            font-size: 1rem;
            line-height: 1.75;
            text-align: left !important;
            box-sizing: border-box;
            margin: 0px 0px 0px 0px;
          }
        }
      }

      @media (max-width: 800px) {
        flex-direction: column;

        .column {
          width: 100%;
          &:last-child {
            margin-top: 32px;
          }
        }
      }
    }

    .content-section-content-bottom {
      padding-top: 32px;
      display: flex;
      flex-direction: row;

      @media (max-width: 800px) {
        flex-direction: column;
      }

      .about-image {
        @media (min-width: 800px) {
          padding-right: 32px;
        }
        width: 100%;
        object-position: center center;
        object-fit: cover;
      }

      .column {
        width: 100%;
      }
      .column-flex {
        width: 100%;
        display: flex;
      }
      .content-pillar {
        // max-width: 235px;

        width: 100%;
        min-width: 150px;

        @media (min-width: 800px) {
          &:first-child {
            padding-right: 32px !important;
          }
        }

        a {
          margin-top: 32px;
        }
      }

      // @media (max-width: 1200px) {
      //   flex-direction: column;
      //   .column-flex {
      //     width: 100%;
      //     justify-content: space-between;
      //   }
      //   .content-pillar {
      //     margin-top: 25px;
      //     width: 50%;
      //     // max-width: 50%;
      //     &:first-child {
      //       padding-right: 0px;
      //     }
      //   }
      // }
    }
  }
}

.content-section-bottom-circle {
  border: $light-grey solid 1px;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  top: -180px;
  position: relative;
  opacity: 0.5;
  animation-fill-mode: both;
}

.mjpls-circle-container {
  position: relative;
  .content-section-top-circle {
    border: $light-grey solid 1px;
    border-radius: 50%;
    width: 400px;
    height: 400px;
    position: absolute;
    top: 0;
    right: -100px;
    opacity: 0.5;
    animation-fill-mode: both;
  }
}
