.footer {
  width: 100%;

  &__row {
    display: grid;

    @media (min-width: 650px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }

    @media (min-width: 992px) {
      grid-template-columns: 200px 200px 200px 1fr;
    }

    @media (min-width: 1100px) {
      grid-template-columns: 250px 250px 250px 1fr;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;

    @media (min-width: 992px) {
      margin-bottom: 40px;
      align-items: flex-start;
    }
  }

  &__heading {
    display: none;
    font-family: 'Akshar';
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 3.83;
    font-size: 36px;
    @media (min-width: 400px) {
      margin-bottom: 32px;
    }
    margin-bottom: 16px;
    color: $white;
    width: fit-content;

    @media (min-width: 992px) {
      display: block;
    }

    &:not(:first-child) {
      margin-top: 40px;
    }

    &:after {
      content: '';
      display: block;
      width: 45%;
      height: 4px;
      position: relative;
      top: 3px;
      background: $blue;
    }
  }

  &__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    flex-wrap: wrap;
    gap: 12px;

    &--container {
      display: flex;
      align-items: center;

      @media (min-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &--gap {
      @media (min-width: 400px) {
        gap: 32px;
      }
      gap: 10px;
    }

    @media (min-width: 992px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__link,
  p {
    color: $white-1;
    font-family: 'Roboto';
    font-size: 16px;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
    margin-top: 10px;

    &:not(:last-child) {
      margin-right: 25px;
    }
    &:hover {
      color: $white;
      margin-bottom: 0 !important;
    }
  }

  &__bottom {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: 400px) {
      flex-direction: row;
    }

    justify-content: space-between;
    margin-bottom: 50px;

    img {
      width: 150px;
    }

    p {
      color: $white-1;
      text-align: center;
    }
  }

  &__logo {
    max-width: 200px;
  }
}
