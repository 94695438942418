.mjpls-page-loading {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: $black;
  z-index: 9999;

  &__word-cycle {
    text-transform: uppercase;
    color: $white;
    font-family: 'Akshar', 'Roboto', sans-serif;
    font-size: 10vw;
    text-align: center;
    -webkit-text-stroke: 0 !important;

    &::before {
      content: '';
      animation: word-cycle 3s ease infinite alternate;
    }
  }

  &__line {
    min-width: 50px;
    width: 100%;
    max-width: 250px;
    height: 5px;
    background-color: $blue;
    position: absolute;
    margin-top: 10%;
  }

  &--fade-out {
    animation: fadeOut ease 1s;
    animation-fill-mode: forwards;
  }
}

@keyframes word-cycle {
  0% {
    content: 'MJPLS';
  }
  10% {
    content: 'Execution';
  }
  20% {
    content: 'Creation';
  }
  30% {
    content: 'Management';
  }
  40% {
    content: 'Innovation';
  }
  50% {
    content: 'Unique';
  }
  60% {
    content: 'Design';
  }
  70% {
    content: 'Lighting';
  }
  80% {
    content: 'Logistics';
  }
  90% {
    content: 'Audio';
  }
  100% {
    content: 'Technology';
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
