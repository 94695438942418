// Colours
$black: #0c0c0c;
$black-1: #141414;

$dark-grey: #1e1d1d;
$grey: #383838;
$light-grey: #828282;
$light-grey-50: #82828250;

$white: #fff;
$white-1: #d8d8d8;

$blue: #161db2;

$red: #fe4b4b;

$green: #04c104;

// Breakpoints
$breakpoint-xs: 400px;
$breakpoint-sm: 450px;
$breakpoint-md: 600px;
$breakpoint-lg: 800px;
$breakpoint-xl: 1200px;
