.mjpls-projects {
  overflow-x: hidden;
  position: relative;
  &__title {
    transform: translateX(300px);
  }

  .background {
    margin-top: 32px;
    background-color: $black-1;
    width: calc(100% - 80px);
    padding: 45px 0;
    z-index: -1;
    margin-left: auto;
    @media (max-width: 930px) {
      width: 100%;
    }
  }

  .splide__track {
    .splide__list {
      .splide__slide {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
          max-width: 800px;
          max-height: 400px;
          width: 100%;
          object-fit: cover;
          object-position: center center;
        }

        h3 {
          margin-top: 32px;
          font-size: 36px;
          text-align: center;
        }

        p {
          margin-top: 0;
          max-width: 600px;
          text-align: center;
        }
      }
    }
  }

  .splide__arrow {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: #828282 solid 1px;
    background-color: transparent;
    cursor: pointer;

    span {
      cursor: pointer;
      background-color: $blue;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      top: 12px;
      left: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;

      svg {
        cursor: pointer;
      }

      &:hover {
        top: 4px;
        left: 4px;
      }
    }
  }
  .splide__arrow--prev {
    span {
      svg {
        rotate: 180deg;
      }
    }
  }

  .mjpls-projects-top-circle {
    border: $grey solid 1px;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    position: absolute;
    top: -160px;
    right: -100px;
    opacity: 0.5;
    animation-fill-mode: both;
  }
}
