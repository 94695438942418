.nav {
    padding-top: 25px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  
    .logo {
      img {
        height: auto;
        width: 10rem;
      }
      &:hover{
          margin-bottom: 0px !important;
      }
    }
  
    /*Styling Links*/
    .nav-links {
      display: flex;
      list-style: none;
      align-items: center;
      li {
        margin-left: 25px;
        position: relative;
      }
    }
  
    .hamburger {
      display: none;
      div {
        width: 25px;
        height: 2px;
        background: #f2f5f7;
        margin: 5px;
        transition: all 0.3s ease;
        &:nth-child(2) {
          width: 15px;
        }
      }
    }
  }
  /*Stying for small screens*/
  @media screen and (max-width: 800px) {
    .disable-scroll {
      overflow-y: hidden;
      height: 100vh;
    }
    .nav {
      position: relative;
      z-index: 3;
      .hamburger {
        display: block;
        position: absolute;
        cursor: pointer;
        right: 5%;
        z-index: 2;
      }
      .nav-links {
        position: fixed;
        top: 0;
        left: 0;
        background: #131418;
        // background: transparent;
        height: 100vh;
        width: 100%;
        flex-direction: column;
        clip-path: circle(50px at 90% -20%);
        -webkit-clip-path: circle(50px at 80% -10%);
        transition: all 1s ease-out;
        pointer-events: none;
        padding: 0 0.7vw;
        display: flex;
        align-items: center;
        justify-content: center;
  
        li {
          opacity: 0;
          margin: 25px 0;
          &:nth-child(1) {
            transition: all 0.5s ease 0.2s;
          }
          &:nth-child(2) {
            transition: all 0.5s ease 0.4s;
          }
          &:nth-child(3) {
            transition: all 0.5s ease 0.6s;
          }
          &:nth-child(4) {
            transition: all 0.5s ease 0.7s;
          }
          &:nth-child(5) {
            transition: all 0.5s ease 0.8s;
          }
          &:nth-child(6) {
            transition: all 0.5s ease 0.9s;
            margin: 0;
          }
          &:nth-child(7) {
            transition: all 0.5s ease 1s;
            margin: 0;
          }
        }
        .fade {
          opacity: 1;
        }
      }
      .close {
        div {
          position: relative;
          transition: ease-in-out 0.3s;
          &:nth-child(1) {
            transform: rotate(45deg);
            top: 3.5px;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            bottom: 3.5px;
          }
        }
      }
      .open {
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(2000px at 80% -10%);
        pointer-events: all;
        .dropdown-link {
          width: 100%;
          text-align: center;
          .dropdown {
            display: block;
            width: 100%;
            .dropdown-menu {
              margin: 10px 10px 0 10px;
              position: relative;
              a {
                width: 100%;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  /*Animating Hamburger Icon on Click*/
  .nav {
    .toggle .line1 {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    .toggle .line2 {
      transition: all 0.7s ease;
      width: 0;
    }
    .toggle .line3 {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }
  