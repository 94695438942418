.mjpls-contentSlider {
  overflow-x: hidden;
  &__title {
    position: relative;
    transform: translateX(300px);
  }

  .background {
    background-color: $black-1;
  }
  .splide {
    margin-top: 60px;
    padding-bottom: 75px;
    .splide__track {
      .splide__list {
        .splide__slide {
          display: flex;
          align-items: flex-start;

          @media (max-width: 930px) {
            flex-direction: column;
            align-items: center;
          }
          .column {
            @media (min-width: 800px) {
              &:first-child {
                margin-right: 50px;
              }
            }
            img {
              width: 500px;
              height: 500px;
              object-fit: cover;
              object-position: center center;
              @media (max-width: 615px) {
                width: 100%;
                height: auto;
              }
            }

            .glide__arrows-contentSlider {
              position: absolute;
              bottom: 8px !important;
              right: 8px !important;
              width: fit-content !important;

              .splide__arrow {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: #828282 solid 1px;
                background-color: transparent;
                cursor: pointer;
                position: relative;

                span {
                  cursor: pointer;
                  background-color: $blue;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  position: absolute;
                  top: 12px;
                  left: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: all 0.3s ease-in-out;

                  svg {
                    cursor: pointer;
                  }

                  &:hover {
                    top: 4px;
                    left: 4px;
                  }
                }
              }

              .glide__arrow--left {
                margin-right: 15px;
              }
            }

            .number-title {
              position: relative;
              min-height: 182px;
              display: flex;
              align-items: center;
              .number {
                position: absolute;
                font-family: 'Akshar';
                font-style: normal;
                font-weight: 400;
                font-size: 182px;
                line-height: 182px;
                letter-spacing: 3.64px;
                color: #383838;
                z-index: -1;
              }

              h2 {
                font-family: 'Akshar';
                font-size: 36px;
                position: relative;
                left: 32px;
              }

              p {
                color: #d8d8d8;
              }
            }
            .contentSlider-list {
              display: flex;
              margin-top: 32px;
              ul {
                list-style: none;
                li {
                  font-family: 'Roboto';
                  margin-bottom: 16px;
                  position: relative;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  .mjpls-contentSlider-left-circle {
    border: #828282 solid 1px;
    border-radius: 50%;
    width: 400px;
    height: 400px;
    position: absolute;
    bottom: 0px;
    left: -100px;
    opacity: 0.5;
    animation-fill-mode: both;
  }

  .mjpls-contentSlider-top-circle {
    border: #828282 solid 1px;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    position: absolute;
    top: 0;
    right: -100px;
    opacity: 0.5;
    animation-fill-mode: both;
  }
}
